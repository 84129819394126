import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const getAllRewards = () => {
  return axios.get(`${API_BASE_URL}/api/reward/list`, {
    withCredentials: true,
  })
}

const deleteReward = (id: string | number) => {
  return axios.delete(`${API_BASE_URL}/api/admin/reward/${id}`, {
    withCredentials: true,
  })
}

const createReward = (
  file,
  title: string,
  shopName: string,
  website: string,
  code: string
) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('title', title)
  formData.append('shopName', shopName)
  formData.append('website', website)
  formData.append('code', code)
  return axios.post(`${API_BASE_URL}/api/admin/reward`, formData, {
    withCredentials: true,
  })
}

const editReward = (
  file,
  title: string,
  shopName: string,
  website: string,
  code: string,
  id: string | number
) => {
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
  }

  formData.append('title', title)
  formData.append('shopName', shopName)
  formData.append('website', website)
  formData.append('code', code)
  return axios.put(`${API_BASE_URL}/api/admin/reward/${id}`, formData, {
    withCredentials: true,
  })
}

const rewardsApi = {
  getAllRewards,
  deleteReward,
  createReward,
  editReward,
}

export default rewardsApi
