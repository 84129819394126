import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'
import InputField from '../../../components/InputField'
import Modal from '../../../components/Modal'
import { Heading2Bold } from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'
import { Form, InputFields, Error, RightSideWrap } from './style'
import couponApi from '../../../api/coupons'
import DropdownSelector from '../../../components/DropdownSelector/DropdownSelector'
import { couponStatusOptions, mediaSourceOptions } from './constants'
import {
  RevenuecatEntitlementDuration,
  AppleGoogleCouponCodes,
} from '../../../types/CouponApiData'
import LabeledField from '../../../types/LabeledField'
import DropdownSelectorWrapper from '../../../components/DropdownSelectorWrapper/DropdownSelectorWrapper'

type ClubEditorProps = {
  closeModal: () => void
  onUpdate: () => void
  couponGroups: string[]
}

export const entitlementDurationOptions = [
  {
    field: RevenuecatEntitlementDuration.daily,
    label: 'One day',
  },
  {
    field: RevenuecatEntitlementDuration.threeDay,
    label: 'Three days',
  },
  {
    field: RevenuecatEntitlementDuration.weekly,
    label: 'Week',
  },
  {
    field: RevenuecatEntitlementDuration.monthly,
    label: 'Month',
  },
  {
    field: RevenuecatEntitlementDuration.twoMonth,
    label: 'Two months',
  },
  {
    field: RevenuecatEntitlementDuration.threeMonth,
    label: 'Three months',
  },
  {
    field: RevenuecatEntitlementDuration.sixMonth,
    label: 'Six months',
  },
  {
    field: RevenuecatEntitlementDuration.yearly,
    label: 'Year',
  },
  {
    field: RevenuecatEntitlementDuration.lifetime,
    label: 'Lifetime',
  },
]

export const appleGoogleCouponCodeOptions = [
  { field: AppleGoogleCouponCodes.runone, label: 'RUNONE' },
  { field: AppleGoogleCouponCodes.megatwo, label: 'MEGATWO' },
  { field: AppleGoogleCouponCodes.coopahthree, label: 'COOPAHTHREE' },
  { field: AppleGoogleCouponCodes.supertwoweek, label: 'SUPERTWOWEEK' },
  { field: AppleGoogleCouponCodes.twentywithtwo, label: 'TWENTYWITHTWO' },
  { field: AppleGoogleCouponCodes.onefor50, label: 'ONEFOR50' },
  { field: AppleGoogleCouponCodes.three25off, label: 'THREE25OFF' },
  { field: AppleGoogleCouponCodes.megaonefor50, label: 'MEGAONEFOR50' },
  { field: AppleGoogleCouponCodes.oneforone, label: 'ONEFORONE' },
  { field: AppleGoogleCouponCodes.coopah2023, label: 'COOPAH2023' },
]

function CouponEditor({ closeModal, onUpdate, couponGroups }: ClubEditorProps) {
  const [error, setError] = useState(null)
  const [title, setTitle] = useState('')
  const [couponCode, setCouponCode] = useState('')
  const [appleCouponCode, setAppleCouponCode] = useState<LabeledField>(null)
  const [googleCouponCode, setGoogleCouponCode] = useState<LabeledField>(null)
  const [entitlementDuration, setEntitlementDuration] =
    useState<LabeledField>(null)
  const [group, setGroup] = useState<LabeledField>(null)
  const [maxRedemptions, setMaxRedemptions] = useState(null)
  const [startDateTime, setStartDateTime] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss')
  )
  const [endDateTime, setEndDateTime] = useState(null)
  const [status, setStatus] = useState(null)
  const [mediaSource, setMediaSource] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [isCreateGroup, setIsCreateGroup] = useState(false)
  const [quantity, setQuantity] = useState(null)

  const groupOptions = useMemo(
    () => couponGroups.map((g) => ({ field: g, label: g })),
    [couponGroups]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sendData = () => {
    return couponApi.createCoupon({
      title,
      code: couponCode,
      appleCouponCode:
        (appleCouponCode?.field as AppleGoogleCouponCodes) ?? null,
      googleCouponCode:
        (googleCouponCode?.field as AppleGoogleCouponCodes) ?? null,
      maxRedemptions: +maxRedemptions || null,
      entitlementDuration:
        entitlementDuration?.field as RevenuecatEntitlementDuration,
      status: status ? status.field : null,
      mediaSource: mediaSource ? mediaSource.field : null,
      campaign,
      startDateTime: moment(startDateTime).toISOString(),
      endDateTime: endDateTime ? moment(endDateTime).toISOString() : null,
      group: group?.field ?? 'default',
    })
  }

  const sendGroupData = () => {
    return couponApi.createCouponsGroup({
      title,
      code: couponCode,
      appleCouponCode:
        (appleCouponCode?.field as AppleGoogleCouponCodes) ?? null,
      googleCouponCode:
        (googleCouponCode?.field as AppleGoogleCouponCodes) ?? null,
      maxRedemptions: +maxRedemptions || null,
      entitlementDuration:
        entitlementDuration?.field as RevenuecatEntitlementDuration,
      quantity,
      status: status ? status.field : null,
      mediaSource: mediaSource ? mediaSource.field : null,
      campaign,
      startDateTime: moment(startDateTime).toISOString(),
      endDateTime: endDateTime ? moment(endDateTime).toISOString() : null,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (isCreateGroup) {
      sendGroupData()
        .then(({ data }) => {
          onUpdate()
          closeModal()
          setMaxRedemptions(null)

          copy(data.map((c) => c.code).join('\n'))
          toast.success('Coupons code copied to clipboard')
        })
        .catch((err) => {
          const errorMessages =
            err?.response?.data?.map((d) => d?.message)?.join(', ') || ''
          if (err.response.status === 422) {
            setError(
              errorMessages ||
                'Cannot save changes. Please make that all required fields are filled.'
            )
          } else {
            setError(errorMessages || 'Something went wrong. Please try again.')
          }
        })
      return
    }
    sendData()
      .then(({ data }) => {
        onUpdate()
        closeModal()

        setMaxRedemptions(null)
        copy(data.code)
        toast.success('Coupon code copied to clipboard')
      })
      .catch((err) => {
        const errorMessages =
          err?.response?.data?.map((d) => d?.message)?.join(', ') || ''
        if (err.response.status === 422) {
          setError(
            errorMessages ||
              'Cannot save changes. Please make that all required fields are filled.'
          )
        } else {
          setError(errorMessages || 'Something went wrong. Please try again.')
        }
      })
  }

  return (
    <Modal onClose={closeModal} width="695px" enableBodyScroll>
      <Heading2Bold>
        {isCreateGroup ? 'Create group of Coupons' : 'Create a Coupon'}
      </Heading2Bold>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            label="Create Coupon Group"
            labelPlacement="top"
            value={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsCreateGroup(e.target.checked)
            }
          />
        </FormGroup>
        <InputFields>
          <RightSideWrap>
            <InputField
              placeholder="Title"
              onChangeEvent={setTitle}
              errorMessage="Required"
              error={error && title === ''}
              defaultValue={title}
            />
            {isCreateGroup && (
              <InputField
                type="number"
                placeholder="Coupons Quantity"
                onChangeEvent={setQuantity}
                errorMessage="Required"
                //  error={error && thirdPartyCode === ''}
                defaultValue={quantity}
              />
            )}
            <InputField
              placeholder="Coupon code"
              onChangeEvent={setCouponCode}
              errorMessage="Required"
              error={error && couponCode === ''}
              defaultValue={couponCode}
            />
            <DropdownSelectorWrapper label="Apple Coupon code">
              <DropdownSelector
                currentSelection={appleCouponCode}
                options={appleGoogleCouponCodeOptions}
                onSelect={setAppleCouponCode}
                placeholder="Select Apple Coupon code"
              />
            </DropdownSelectorWrapper>

            <DropdownSelectorWrapper label="Google Coupon code">
              <DropdownSelector
                currentSelection={googleCouponCode}
                options={appleGoogleCouponCodeOptions}
                onSelect={setGoogleCouponCode}
                placeholder="Select Google Coupon code"
              />
            </DropdownSelectorWrapper>

            <DropdownSelectorWrapper label="Revenuecat Entitlement Duration">
              <DropdownSelector
                currentSelection={entitlementDuration}
                options={entitlementDurationOptions}
                onSelect={setEntitlementDuration}
                placeholder="Select duration"
                disabled={!!googleCouponCode || !!appleCouponCode}
              />
            </DropdownSelectorWrapper>

            <InputField
              placeholder="Max redemptions"
              onChangeEvent={setMaxRedemptions}
              defaultValue={maxRedemptions}
              type="number"
            />
            <InputField
              placeholder="Start date"
              onChangeEvent={setStartDateTime}
              defaultValue={startDateTime}
              type="datetime-local"
            />
            <InputField
              placeholder="End date"
              onChangeEvent={setEndDateTime}
              defaultValue={endDateTime}
              type="datetime-local"
            />
            <DropdownSelectorWrapper label="Coupon Status">
              <DropdownSelector
                currentSelection={status}
                options={couponStatusOptions}
                onSelect={setStatus}
                placeholder="Select a coupon status"
                disabled={!!googleCouponCode || !!appleCouponCode}
              />
            </DropdownSelectorWrapper>

            <DropdownSelectorWrapper label="Media Source">
              <DropdownSelector
                currentSelection={mediaSource}
                options={mediaSourceOptions}
                onSelect={setMediaSource}
                placeholder="Select a coupon media source"
              />
            </DropdownSelectorWrapper>
            <InputField
              placeholder="Campaign"
              onChangeEvent={setCampaign}
              defaultValue={campaign}
            />

            {!isCreateGroup && (
              <DropdownSelectorWrapper label="Group">
                <DropdownSelector
                  currentSelection={group}
                  options={groupOptions}
                  onSelect={setGroup}
                  placeholder="Select existing group"
                />
              </DropdownSelectorWrapper>
            )}
          </RightSideWrap>
        </InputFields>
        {error && <Error>{error}</Error>}
        <Button secondary type="submit">
          {isCreateGroup ? 'Create group of Coupons' : 'Create Coupon'}
        </Button>
        <TextButton type="button" onClick={closeModal}>
          Cancel
        </TextButton>
      </Form>
    </Modal>
  )
}

export default CouponEditor
