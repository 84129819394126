import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

interface CreateAccount {
  password: string
  email: string
  firstName: string
  lastName: string
  unitSystem: 'metric' | 'imperial'
  reminderNotification: boolean
  subscription: boolean
}

const login = (email, password) => {
  return axios.post(
    `${API_BASE_URL}/api/auth/admin-login-factor1`,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  )
}

const loginUser = (email, password) => {
  return axios.post(
    `${API_BASE_URL}/api/auth/login-factor1`,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  )
}

const createAccount = ({
  firstName,
  lastName,
  email,
  password,
  unitSystem,
  reminderNotification,
  subscription,
}: CreateAccount) => {
  return axios.post(
    `${API_BASE_URL}/api/auth/account-create`,
    {
      firstName,
      lastName,
      email,
      password,
      unitSystem,
      reminderNotification,
      subscription,
    },
    {
      withCredentials: true,
    }
  )
}

const sendResetEmail = (email) => {
  return axios.post(`${API_BASE_URL}/api/auth/password-reset1`, { email })
}

const resetPassword = (email, password, code) => {
  return axios.post(`${API_BASE_URL}/api/auth/password-reset2`, {
    email,
    password,
    code,
  })
}

const validateResetCode = (code: string, email: string) => {
  return axios.post(`${API_BASE_URL}/api/auth/validate-reset-code`, {
    code,
    email,
  })
}

const logout = () => {
  return axios.post(
    `${API_BASE_URL}/api/auth/logout`,
    {},
    {
      withCredentials: true,
    }
  )
}

const getUser = () => {
  return axios.get(`${API_BASE_URL}/api/user/me`, {
    withCredentials: true,
  })
}

const authApi = {
  login,
  createAccount,
  sendResetEmail,
  resetPassword,
  logout,
  validateResetCode,
  getUser,
  loginUser,
}

export default authApi
