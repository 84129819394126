import axios from 'axios'
import { TrainingPlan } from '../pages/UserTrainingPlans/TrainingPlansTable/utils'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const getAllTrainingPlans = () => {
  return axios.get(`${API_BASE_URL}/api/admin/trainingplan/templates`, {
    withCredentials: true,
  })
}

const getTemplate = () => {
  return axios.get(`${API_BASE_URL}/api/admin/trainingplan/sampletemplate`)
}

const uploadPlan = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`${API_BASE_URL}/api/admin/trainingplan/upload`, formData, {
    withCredentials: true,
  })
}

const getUserTrainingPlans = (userId: number) => {
  return axios.get(`${API_BASE_URL}/api/admin/trainingplan/users/${userId}`)
}

const updateUserTrainingPlan = (
  userId: number,
  planId: number,
  plan: TrainingPlan
) => {
  return axios.put(
    `${API_BASE_URL}/api/admin/user/${userId}/plans/${planId}`,
    plan
  )
}

const trainingplansApi = {
  getAllTrainingPlans,
  getTemplate,
  uploadPlan,
  getUserTrainingPlans,
  updateUserTrainingPlan,
}

export default trainingplansApi
